import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "./assets/styles/sass/global.scss";
import i18n from "./system/lang/lang.js";
import { createPinia } from "pinia";
import VueQRCodeComponent from 'vue-qrcode-component';
import VueGoodTablePlugin from 'vue-good-table';
import VueMarqueeSlider from 'vue-marquee-slider';
import 'vue-good-table/dist/vue-good-table.css'
import {
  getMemberInfo, getUserNewsList, getPackage, getBannerList
} from "@/system/request/api/api";
Vue.use(VueMarqueeSlider)
Vue.use(VueGoodTablePlugin);
Vue.component('qr-code', VueQRCodeComponent);

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(createPinia())

Vue.config.productionTip = false;
Vue.prototype.$bus = new Vue();

new Vue({
  data() {
    return {
      isLoading: false,
      needRiskLevel: false,
      cannotUpgrade: true,
      userInfo: {
        invest: 0,
        user_group_id: 0,
        rank: {
          rank_name_en: ''
        },
        package: {
          price: 0,
        }
      },
      selectedWallet: '',
      currentAddress: '',
      username: '',
      memberList: [],
      teamList: [],
      nodeList: [],
      newsList: [],
      packageList: [],
      newsItem: {
        description: ''
      },
      bannerList: [],
      assetInfo: {
        static_bonus: 0,
        special_bonus: 0,
        dynamic_bonus: 0,
        level_bonus: 0,
      },
      modal: {
        title: "",
        msg: "",
        action: false,
        error: false,
      }
    }
  },
  watch: {
    '$i18n.locale'()
      {this.getNewsList();this.getBannerList()},
    'userInfo': 'checkCurrentDate',
  },
  methods: {
    changeLan(lan) {
      if (lan == 'zh' || lan == 'en' || lan == 'in' || lan == 'zhHant' || lan == 'ja' || lan == 'ko') {
        localStorage.setItem('lan', lan);
        this.$i18n.locale = lan;

      } else {
        this.$root.makeToast("warning", this.$t('coming_soon'));
      }
    },
    getStakingType() {
      var self = this;
      self.isLoading = true;
      var result = getPackage();
      result
        .then(function (value) {
          self.packageList = value.data.data.user_group;
          self.isLoading = false;


        })
        .catch(function (error) {
          console.log(error);
        });
    },
    checkCurrentDate() {
      if (this.userInfo.id == '1000057') {
        this.cannotUpgrade = false;
        console.log(this.cannotUpgrade);
        return;
      }
      this.cannotUpgrade = true;
      var todayDate = new Date().getDate();
      if (todayDate <= 3) {
        if (this.userInfo.user_group_id == 0 || this.userInfo.user_group_id >= 3) {
          this.cannotUpgrade = false;

        }
        if (this.userInfo.user_group_id >= 3 && this.userInfo.risk_level == 0)
          this.needRiskLevel = true;

      }else{
        if (this.userInfo.user_group_id == 0) {
          this.cannotUpgrade = false;

        }
      }

      console.log(this.cannotUpgrade);
    },
    getInfo() {
      var self = this;
      self.isLoading = true;
      var result = getMemberInfo();
      result
        .then(function (value) {
          self.isLoading = false;
          self.userInfo = value.data.data;


        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getBannerList() {
      // this.$i18n.locale == 'zh' ? 'cn' : 'en'
      this.bannerList = [];
      var result = getBannerList(this.$i18n.locale == 'zh' ? 'cn' : 'en', 2, 1);
      var self = this;
      result
        .then(function (value) {

          self.bannerList = value.data.data;
          console.log(self.bannerList);


        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getNewsList() {
      var result = getUserNewsList(this.$i18n.locale == 'zh' ? 'cn' : this.$i18n.locale, 1, 1);
      var self = this;
      result
        .then(function (value) {
          self.newsList = value.data.data.record.data;


        })
        .catch(function (error) {
          console.log(error);
        });
    },
    makeToast(variant = null, msg, type) {
      this.msg = msg;
      this.variant = variant;
      this.type = type;
      this.$bvToast.toast(msg, {
        // // title: ` ${variant || "default"}`,
        variant: variant,
        solid: true,
      });
    },
    checkKYC() {
      if (this.userInfo.check_kyc.can_withdraw == 'approved') {
        this.$bvModal.show('withdraw');

      } else {
        this.modal = {
          title: this.$t('kyc'),
          msg: this.$t('kyc_hint'),
          action: false,
          error: true,
        };
        this.$bvModal.show('modal-errorMsg');

      }
    },
    clearErrorMsg() {
      this.$root.modal = {
        title: "",
        msg: "",
        action: false,
        error: false,
      };
      console.log(this.modal);

    }
  },
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
